import JsonObject from '../types/JsonObject';

export const Meta = (data: JsonObject) => ({
  currentPage: data.current_page,
  from: data.from,
  lastPage: data.last_page,
  perPage: data.per_page,
  to: data.to,
  total: data.total,
});

export interface Meta extends ReturnType<typeof Meta> {}
