import { TableCell } from '../components/Table';
import Badge from '../components/Badge';
import { isPast, todayOrDate } from '../services/utils/date';

const UserTable: TableCell<any>[] = [
  {
    label: 'Name',
    fixed: 'left',

    value: (data: any) => {
      return (
        <>
          {data.name}
          {data.interim && (
            <Badge color="yellow" className="ml-2">
              Interim
            </Badge>
          )}
          {data.deletedAt && (
            <Badge color="red" className="ml-2">
              Suspended
            </Badge>
          )}
        </>
      );
    },
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Role',
    value: 'role.name',
  },
  {
    label: 'Subscription Ends',
    value: (data) => {
      return (
        <Badge color={isPast(data.subscription_ends) ? 'red' : 'green'}>
          {data.subscription_ends ? todayOrDate(data.subscription_ends) : '-'}
        </Badge>
      );
    },
  },
  {
    label: 'Latest Payment',
    value: (data, openDialog) => {
      return (
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        <button onClick={() => openDialog?.('orders', { orders: data.orders ?? [] })}>
          <Badge>{data.lastOrder ? todayOrDate(data.lastOrder) : '-'}</Badge>
        </button>
      );
    },
  },
  {
    label: 'Last Login',
    value: (data: any) => {
      return <Badge>{data.lastLogin ? todayOrDate(data.lastLogin) : '-'}</Badge>;
    },
  },
];

export default UserTable;
