import JsonObject from '../types/JsonObject';
import { University } from '../factories/University';

export class Hospital {
  public id: number;
  public name: string;
  public university?: University;

  constructor(data: JsonObject) {
    this.id = data.id;
    this.name = data.name;
    this.university = data.university ? new University(data.university) : undefined;
  }
}
