import { TableCell } from '../components/Table';

const DefaultTable: TableCell<any>[] = [
  {
    label: 'Name',
    value: 'name',
  },
];

export default DefaultTable;
