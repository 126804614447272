import { TableCell } from '../components/Table';

const InterestTable: TableCell<any>[] = [
  {
    label: 'Name',
    value: 'value',
  },
];

export default InterestTable;
