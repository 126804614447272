import Router from '../services/Router';
import { AuthProvider } from '../contexts/AuthContext';
import { PortalProvider } from '../components/Portal';
import { ToastWrapper } from '@ajoelp/toast';
import { QueryClientProvider } from 'react-query';
import { DialogManager } from './Dialogs/DialogManager';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import Typography from './Typography';
import { ToastMessage } from './ToastMessage';
import { MaintenanceModeProvider } from '../contexts/MaintenanceModeContext';
import { AppQueryClient } from '../contexts/QueryClient';
import { environment } from '../../environments/environment.prod';

function ErrorFallback({ error }: FallbackProps) {
  return (
    <div role="alert" className="w-screen h-screen fixed top-0 left-0 bg-white flex items-center justify-center">
      <div className="text-center">
        <Typography type="title">Something went wrong:</Typography>
        <Typography>The issue has been reported and will be addressed as soon as possible.</Typography>
        {!environment.production && (
          <div className="whitespace-pre text-left bg-gray-100 p-4 my-8 text-gray-700 shadow-xl rounded">
            {error?.stack}
          </div>
        )}
      </div>
    </div>
  );
}

export default function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <MaintenanceModeProvider>
        <QueryClientProvider client={AppQueryClient}>
          <AuthProvider>
            <DialogManager>
              <PortalProvider>
                <Router />
                <ToastWrapper className="max-w-sm w-full z-50 fixed top-0 right-0" toastContainer={ToastMessage} />
              </PortalProvider>
            </DialogManager>
          </AuthProvider>
        </QueryClientProvider>
      </MaintenanceModeProvider>
    </ErrorBoundary>
  );
}
