import { TableCell } from '../components/Table';
import Badge from '../components/Badge';

const PageTable: TableCell<any>[] = [
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'Roles',
    value: (data) => {
      return (
        <div>
          {(data.roles_names ?? []).map((role: string) => (
            <Badge key={role} color="blue">
              {role}
            </Badge>
          ))}
        </div>
      );
    },
  },
];

export default PageTable;
