import { timestamps } from './helpers';
import JsonObject from '../types/JsonObject';

export const Page = (data: JsonObject) => ({
  id: data.id,
  name: data.name,
  slug: data.slug,
  template: data.template,
  content: data.content,
  roles_names: data.roles_names ?? [],
  ...timestamps(data),
});

export interface Page extends ReturnType<typeof Page> {}
