import * as React from 'react';

export default function Loader({ className, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="35pt"
      height="35pt"
      version="1.1"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      className={`animate-spin ${className}`}
      {...props}
    >
      <g>
        <path
          d="m85 34.301c0.80078 0 1.5-0.19922 2.3008-0.60156 2.1992-1.3008 2.8984-4 1.6016-6.1016-1.3008-2.1992-4-2.8984-6.1016-1.6016-2.1992 1.1992-2.8984 4-1.6016 6.1992 0.69922 1.3047 2.1992 2.1055 3.8008 2.1055z"
          fill="currentColor"
        />
        <path
          d="m67.898 18.898c0.69922 0.39844 1.5 0.60156 2.3008 0.60156 1.5 0 3.1016-0.80078 3.8984-2.3008 1.1992-2.1016 0.5-4.8984-1.6016-6.1016-2.1992-1.1992-4.8984-0.5-6.1992 1.6016-1.1953 2.1992-0.49609 4.9023 1.6016 6.1992z"
          fill="currentColor"
        />
        <path
          d="m50 94.898c24.801 0 44.898-20.102 44.898-44.898 0-2.5-2-4.5-4.5-4.5s-4.5 2-4.5 4.5c0 19.801-16.102 35.898-35.898 35.898-19.801 0-35.898-16.098-35.898-35.898s16.102-35.898 35.898-35.898c2.5 0 4.5-2 4.5-4.5s-2-4.5-4.5-4.5c-24.801 0-44.898 20.098-44.898 44.898s20.098 44.898 44.898 44.898z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
