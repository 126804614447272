import apiClient from './api-client';
import JsonObject from '../types/JsonObject';
import { AUTH_TOKEN_KEY, REFRESH_TOKEN_KEY, IMPERSONATE_TOKEN } from '../constants';

export async function impersonate(userId: number | string) {
  const response = await apiClient(`api/impersonate/${userId}`, 'post');
  window.localStorage.setItem(IMPERSONATE_TOKEN, response?.data?.impersonate_token);
}

export function login(credentials: JsonObject) {
  return apiClient('api/login', 'post', {
    ...credentials,
  });
}

export async function register(data: JsonObject) {
  return apiClient('register', 'post', data);
}

export async function logout() {
  if (window.localStorage.getItem(IMPERSONATE_TOKEN) != null) {
    window.localStorage.removeItem(IMPERSONATE_TOKEN);
    return Promise.resolve();
  }

  window.localStorage.removeItem(AUTH_TOKEN_KEY);
  window.localStorage.removeItem(REFRESH_TOKEN_KEY);
  return Promise.resolve();
}

export async function me() {
  return apiClient('api/me', 'get', {}, { authRedirects: false });
}
