import { timestamps } from './helpers';
import JsonObject from '../types/JsonObject';

export const Role = (data: JsonObject) => ({
  id: data.id,
  name: data.name,
  slug: data.slug,
  ...timestamps(data),
});

Role.phpModel = 'AppRole';

export interface Role extends ReturnType<typeof Role> {}
