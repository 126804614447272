import JsonObject from '../types/JsonObject';
import { timestamps } from '../factories/helpers';

export enum ResourceTypes {
  VIDEO = 'video',
  LINK = 'link',
  STREAM = 'stream',
  MODULE = 'module',
  CONTENT = 'content',
}

export const ResourceTypeLabels = {
  [ResourceTypes.VIDEO]: 'Video',
  [ResourceTypes.LINK]: 'Link',
  [ResourceTypes.STREAM]: 'Stream',
  [ResourceTypes.MODULE]: 'Learning Module',
  [ResourceTypes.CONTENT]: 'Content',
};

export const EducationalResource = (data: JsonObject) => ({
  id: data.id,
  name: data.name,
  slug: data.slug,
  ...timestamps(data),
});

export interface EducationalResource extends ReturnType<typeof EducationalResource> {
  content: (JsonObject & { type: ResourceTypes })[];
}
