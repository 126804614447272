import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

type Portals = {
  [key: string]: HTMLDivElement;
};

interface PortalContext {
  portals: Portals;
  setPortal(key: string, ref: HTMLDivElement): void;
}

const PortalContext = createContext<PortalContext>({
  portals: {},
  setPortal() {},
});

export function PortalProvider(props: PropsWithChildren<{}>) {
  const [portals, setPortals] = useState<Portals>({});

  const setPortal = useCallback(
    (key: string, ref: HTMLDivElement) => {
      setPortals({
        ...portals,
        [key]: ref,
      });
    },
    [portals]
  );

  return <PortalContext.Provider value={{ portals, setPortal }}>{props.children}</PortalContext.Provider>;
}

interface PortalProps {
  name: string;
}

export function Portal(props: PropsWithChildren<PortalProps>) {
  const { portals } = useContext(PortalContext);
  const mount = portals[props.name];
  const el = document.createElement('div');
  useEffect(() => {
    if (!mount) {
      return;
    }
    mount.appendChild(el);
    return () => {
      mount.removeChild(el);
    };
  }, [el, mount, props.name]);

  return createPortal(props.children, el);
}

interface PortalTargetProps {
  name: string;
}

export function PortalTarget(props: PortalTargetProps) {
  const portalContext = useContext(PortalContext);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current && !portalContext.portals[props.name]) {
      portalContext.setPortal(props.name, ref.current!);
    }
  }, [portalContext, props.name]);
  return <div ref={ref} />;
}
