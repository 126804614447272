import * as Sentry from '@sentry/react';
import { User } from '../models/User';
import { environment } from '../../environments/environment.prod';

class Analytics {
  sentryKey = environment.sentryDSN;

  get hasSentry() {
    return !!this.sentryKey;
  }

  async initialize() {
    if (this.hasSentry) {
      console.log('[Analytics] Sentry initialized');
      Sentry.init({
        dsn: this.sentryKey,
      });
    }
  }

  async authenticate(user: User) {
    if (this.hasSentry) {
      console.log('[Analytics] Sentry user context added');
      Sentry.setUser({
        id: `${user.id}`,
        email: user.email,
      });
    }
  }
}

export default new Analytics();
