import { Icon } from '@ajoelp/icons';
import { ReactNode, useEffect, useRef } from 'react';

const types = {
  error: {
    backgroundColor: 'bg-red-50',
    textColor: 'text-red-700',
    icon: 'XCircle',
    iconClass: 'h-5 w-5 text-red-400',
  },
  info: {
    backgroundColor: 'bg-blue-50',
    textColor: 'text-blue-700',
    icon: 'InformationCircle',
    iconClass: 'h-5 w-5 text-blue-400',
  },
  warning: {
    backgroundColor: 'bg-yellow-50',
    textColor: 'text-yellow-700',
    icon: 'Exclamation',
    iconClass: 'h-5 w-5 text-yellow-400',
  },
  success: {
    backgroundColor: 'bg-green-50',
    textColor: 'text-green-700',
    icon: 'CheckCircle',
    iconClass: 'h-5 w-5 text-green-400',
  },
};

export interface AlertProps {
  type?: keyof typeof types;
  children: ReactNode;
  className?: string;
  name?: string;
  demand?: boolean;
}

export default function Alert({ type = 'info', children, className = '', name, demand }: AlertProps) {
  const { icon, iconClass, backgroundColor, textColor } = types[type];
  const alertRef = useRef<HTMLDivElement>(null);
  const alreadyScrolled = useRef(false);

  const id = ['alert', name].filter(Boolean).join('_');

  useEffect(() => {
    if (demand && !alreadyScrolled.current) {
      alreadyScrolled.current = true;
      alertRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [demand]);

  return (
    <div id={id} ref={alertRef} data-testid={id} className={`rounded-md ${backgroundColor} p-4 ${className}`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon className={iconClass} icon={icon as any} variant="outline" />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className={`text-sm leading-5 ${textColor}`}>{children}</p>
        </div>
      </div>
    </div>
  );
}
