import JsonObject from '../types/JsonObject';
import { User } from '../models/User';
import { Hospital } from '../factories/Hospital';
import { Model } from '../models/Model';
import { DateObject } from '../models/DateObject';
import { FinalEvaluation } from './FinalEvaluation';

export enum FellowshipRoles {
  FELLOW = 'fellow',
  EVALUATOR = 'evaluator',
  PROGRAM_DIRECTOR = 'program_director',
}

export const roleLabels: { [key in FellowshipRoles]: string } = {
  [FellowshipRoles.FELLOW]: 'Fellow',
  [FellowshipRoles.EVALUATOR]: 'Evaluator',
  [FellowshipRoles.PROGRAM_DIRECTOR]: 'Program Director',
};

export enum FellowshipPrograms {
  CERTIFICATE = 'certificate',
  STROKE_EXPERT = 'stroke_expert',
  STROKE_SCHOLAR = 'stroke_scholar',
}

export const programLabels: { [key in FellowshipPrograms]: string } = {
  [FellowshipPrograms.CERTIFICATE]: 'Certificate',
  [FellowshipPrograms.STROKE_EXPERT]: 'Stroke Expert',
  [FellowshipPrograms.STROKE_SCHOLAR]: 'Stroke Scholar',
};

export class FellowshipProfile extends Model {
  public id: number;
  public userId: number;
  public programSelection: FellowshipPrograms;
  public specialty: string;
  public hospitalId: number;
  public cv: string;
  public confirmationSentAt?: DateObject;
  public startDate?: DateObject;
  public anticipatedEndDate?: DateObject;
  public feesPaidAt?: DateObject;
  public acceptedAt?: DateObject;
  public deniedAt?: DateObject;
  public state: string;
  public fellowshipRole: FellowshipRoles;
  public user?: User;
  public hospital?: Hospital;
  public programDirectorId: number;
  public reportCard?: JsonObject;
  public researchRounds: string[];
  public requiresPayment: boolean;
  public isVettingCommittee: boolean;
  public approvedForFinalExamAt?: DateObject;
  public completedFinalExamAt?: DateObject;
  public medicalResidencyTraining?: string;
  public medicalResidencyTrainingSpecify?: string;
  public comments?: string;
  public finalEvaluation?: FinalEvaluation;

  public completedAt?: DateObject;
  public deletedAt?: DateObject;
  public withdrawnAt?: DateObject;

  constructor(data: JsonObject) {
    super();
    this.id = data.id;
    this.userId = data.user_id;
    this.programDirectorId = data.program_director_id;
    this.programSelection = data.program_selection;
    this.specialty = data.specialty;
    this.hospitalId = data.hospital_id;
    this.cv = data.cv;
    this.state = data.state;
    this.fellowshipRole = data.fellowship_role;
    this.user = data.user ? new User(data.user) : undefined;
    this.hospital = data.hospital ? new Hospital(data.hospital) : data.hospital;
    this.reportCard = data.report_card;
    this.researchRounds = data.research_rounds ?? [];
    this.requiresPayment = data.requires_payment ?? false;
    this.isVettingCommittee = data.is_vetting_committee ?? false;
    this.approvedForFinalExamAt = data.approved_for_final_exam_at;
    this.completedFinalExamAt = data.completed_final_exam_at;
    this.medicalResidencyTraining = data.medical_residency_training;
    this.medicalResidencyTrainingSpecify = data.medical_residency_training_specify;
    this.comments = data.comments;
    this.finalEvaluation = data.final_evaluation ? new FinalEvaluation(data.final_evaluation) : undefined;
    this.buildTimestamps(
      [
        'created_at',
        'updated_at',
        'start_date',
        'anticipated_end_date',
        'fees_paid_at',
        'accepted_at',
        'denied_at',
        'confirmation_sent_at',
        'approved_for_final_exam_at',
        'completed_final_exam_at',
        'completed_at',
        'deleted_at',
        'withdrawn_at',
      ],
      data
    );
  }

  get roleLabel() {
    return roleLabels[this.fellowshipRole];
  }

  get programLabel() {
    return programLabels[this.programSelection];
  }

  get isEvaluator() {
    return this.fellowshipRole === FellowshipRoles.EVALUATOR;
  }

  get isProgramDirector() {
    return this.fellowshipRole === FellowshipRoles.PROGRAM_DIRECTOR;
  }

  get isFellow() {
    return this.fellowshipRole === FellowshipRoles.FELLOW;
  }

  toJson() {
    return {
      id: this.id,
      user_id: this.userId,
      program_selection: this.programSelection,
      specialty: this.specialty,
      hospital_id: this.hospitalId,
      cv: this.cv,
      confirmation_sent_at: this.confirmationSentAt,
      start_date: this.startDate,
      anticipated_end_date: this.anticipatedEndDate,
      fees_paid_at: this.feesPaidAt,
      accepted_at: this.acceptedAt,
      denied_at: this.deniedAt,
      state: this.state,
      fellowship_role: this.fellowshipRole,
      program_director_id: this.programDirectorId,
      research_rounds: this.researchRounds,
      is_vetting_committee: this.isVettingCommittee,
      approved_for_final_exam_at: this.approvedForFinalExamAt,
      completed_final_exam_at: this.completedFinalExamAt,
      medical_residency_training: this.medicalResidencyTraining,
      medical_residency_training_specify: this.medicalResidencyTrainingSpecify,
      completed_at: this.completedAt,
      comments: this.comments,
      final_evaluation: this.finalEvaluation,
    };
  }
}
