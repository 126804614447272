import JsonObject from '../types/JsonObject';
import UrlPattern from 'url-pattern';

export default function buildUrl(url: string, data: JsonObject) {
  try {
    return new UrlPattern(url).stringify(data);
  } catch (e) {
    return '';
  }
}
