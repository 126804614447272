import { ReactNode } from 'react';

const COLORS = {
  gray: 'bg-gray-100 text-gray-800 border-gray-200',
  red: 'bg-red-100 text-red-800 border-red-200',
  orange: 'bg-orange-100 text-orange-800 border-orange-200',
  yellow: 'bg-yellow-100 text-yellow-800 border-yellow-200',
  green: 'bg-green-100 text-green-800 border-green-200',
  teal: 'bg-teal-100 text-teal-800 border-teal-200',
  blue: 'bg-blue-100 text-blue-800 border-blue-200',
  indigo: 'bg-indigo-100 text-indigo-800 border-indigo-200',
  purple: 'bg-purple-100 text-purple-800 border-purple-200',
  pink: 'bg-pink-100 text-pink-800 border-pink-200',
};

export type BadgeColors = keyof typeof COLORS;

interface BadgeProps {
  children: ReactNode;
  color?: BadgeColors;
  className?: string;
  rounding?: boolean;
}

export default function Badge({ children, color = 'gray', className, rounding = true }: BadgeProps) {
  return (
    <span
      className={`inline-flex items-center px-2 py-0.5 ${rounding ? 'rounded' : ''} text-xs font-medium leading-4 ${
        COLORS[color]
      } ${className}`}
    >
      {children}
    </span>
  );
}
