import { TableCell } from '../components/Table';

const GraduatesTable: TableCell<any>[] = [
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'Year',
    value: 'year',
  },
  {
    label: 'Status',
    value: (data) => {
      return <div className="flex w-full">{data.private ? 'Private' : 'Public'}</div>;
    },
  },
];

export default GraduatesTable;
