import { createContext, FC, useCallback, useContext, useEffect, useState } from 'react';
import Typography from '../components/Typography';
import Logo from '../components/Logo';
import { useBrowserQueryParams } from '../services/hooks/useRouterQueryParams';
import apiClient from '../services/api-client';

interface MaintenanceModeContextState {
  setMaintenanceMode(value: boolean): void;
  active: boolean;
}

const MaintenanceModeContext = createContext<MaintenanceModeContextState>({
  setMaintenanceMode(value: boolean) {},
  active: false,
});

const MaintenenceModeScreen = () => {
  const {
    params: { secret },
  } = useBrowserQueryParams({});

  const fetchCookie = useCallback(async () => {
    try {
      await apiClient(`${secret}`, 'get');
      window.location.reload();
    } catch (e) {}
  }, [secret]);

  useEffect(() => {
    if (secret) {
      fetchCookie();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center bg-white">
      <div style={{ maxWidth: 500, width: '100%' }} className="mb-8">
        <Logo />
      </div>
      <Typography type="title">Maintenance Mode</Typography>
      <Typography type="subtitle">Please check back later to see if the status has changed.</Typography>
    </div>
  );
};

export const MaintenanceModeProvider: FC = ({ children }) => {
  const [active, setMaintenanceMode] = useState(false);
  return (
    <MaintenanceModeContext.Provider value={{ active, setMaintenanceMode }}>
      {active ? <MaintenenceModeScreen /> : children}
    </MaintenanceModeContext.Provider>
  );
};

export const useMaintenanceMode = () => {
  const context = useContext(MaintenanceModeContext);
  if (context == null) {
    throw new Error('useMaintenanceMode must be used inside a MaintenanceModeProvider');
  }
  return context;
};
