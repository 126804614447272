import { DateObject } from '../models/DateObject';
import JsonObject from '../types/JsonObject';
import camelCase from 'lodash/camelCase';

export class Model {
  buildTimestamps(timestamps: string[] = ['created_at', 'updated_at'], data: JsonObject) {
    timestamps.forEach((timestamp) => {
      (this as any)[camelCase(timestamp)] = DateObject.create(data[timestamp]);
    });
  }
}
