import JsonObject from '../types/JsonObject';
import { timestamps } from '../factories/helpers';

export const Module = (data: JsonObject) => ({
  id: data.id,
  name: data.name,
  slug: data.slug,
  type: data.type,
  description: data.description,
  active: data.active,
  video_url: data.video_url,
  questions_count: data.questions_count,
  branding: data.branding ?? {},
  settings: data.settings ?? {},
  ...timestamps(data),
});

export interface Module extends ReturnType<typeof Module> {}

Module.softDeletes = true;
