import { createContext, useEffect, useCallback, useMemo, useContext, PropsWithChildren, useState } from 'react';
import useAsync from '../services/hooks/useAsync';
import * as auth from '../services/auth-provider';
import JsonObject from '../types/JsonObject';
import { LoadingOverlay } from '../components/Loading';
import { User } from '../models/User';
import Analytics from '../services/analytics';
import { useMaintenanceMode } from '../contexts/MaintenanceModeContext';
import { makeModel } from '../services/model-mutators';
import { AppQueryClient } from '../contexts/QueryClient';

interface AuthContextInterface {
  login(args: JsonObject): Promise<any>;
  logout(args: JsonObject): Promise<any>;
  bootstrap(): any;
  user?: User;
  error: any;
}

export const AuthContext = createContext<AuthContextInterface | undefined>(undefined);
AuthContext.displayName = 'AuthContext';

async function bootstrap(handleError?: (e: any) => void) {
  let user: User | undefined = undefined;
  try {
    const { data } = await makeModel(auth.me(), User);
    await Analytics.authenticate(data);
    user = data;
  } catch (e) {
    handleError?.(e);
  }
  return user;
}

export function AuthProvider(props: PropsWithChildren<{}>) {
  const { data: user, status, isLoading, isIdle, isError, isSuccess, run, setData } = useAsync<User | undefined>();

  const [error, setError] = useState(null);

  const { setMaintenanceMode } = useMaintenanceMode();

  const handleError = useCallback(
    (error: any) => {
      setError(error);
      if (error?.response?.status === 503) {
        setMaintenanceMode(true);
      }
    },
    [setMaintenanceMode]
  );

  useEffect(() => {
    setError(null);
    run(bootstrap(handleError));
  }, [handleError, run]);

  const login = useCallback((data: JsonObject) => auth.login(data).then(() => run(bootstrap())), [run]);
  const logout = useCallback(async () => {
    await auth.logout();
    await AppQueryClient.invalidateQueries();
    setData(null);
    window.location.href = '/';
  }, [setData]);

  const value = useMemo(
    () => ({
      login,
      logout: () => run(logout()),
      user,
      bootstrap: () => run(bootstrap()),
      error,
    }),
    [login, logout, user, run, error]
  );

  if (isLoading || isIdle) {
    return <LoadingOverlay />;
  }

  if (isError) {
    // TODO: some sort of error overlay
    return <div />;
  }

  if (isSuccess) {
    return <AuthContext.Provider value={value} {...props} />;
  }

  throw new Error(`Unhandled status: ${status}`);
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}
