import { timestamps } from './helpers';
import JsonObject from '../types/JsonObject';

export const Interest = (data: JsonObject) => ({
  id: data.id,
  value: data.value,
  ...timestamps(data),
});

export interface Interest extends ReturnType<typeof Interest> {}
