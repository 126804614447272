import JsonObject from '../types/JsonObject';

export interface MenuItem {
  type: 'page' | 'dropdown' | 'link';
  title: string;
  slug?: string;
  link?: string;
  children?: MenuItem[];
  is_members?: boolean;
}

export const Menu = (data: JsonObject) => ({
  name: data.key.toUpperCase(),
  key: data.key,
  config: data.config,
});

export interface Menu extends ReturnType<typeof Menu> {
  config: MenuItem[];
}

export const generateLink = (item: MenuItem) => {
  if (item.type === 'link') {
    return item.link;
  }

  if (item.type === 'page') {
    // TODO: if is members page
    return `/${item.slug}`;
  }

  return '#';
};
