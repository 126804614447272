import { useContext, createContext } from 'react';
import RouteStructure from '../types/RouteStructure';

export const CurrentRouteContext = createContext<RouteStructure | null>(null);

export const useCurrentRoute = () => {
  const route = useContext(CurrentRouteContext);
  if (route === undefined) {
    throw new Error('useCurrentRoute must be used inside a CurrentRouteContext');
  }
  return route;
};
