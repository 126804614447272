import JsonObject from '../types/JsonObject';
import moment from 'moment';

export interface HasTimestamps {
  createdAt: moment.Moment;
  updatedAt: moment.Moment;
  deletedAt?: moment.Moment;
}

export const timestamps = (data: JsonObject): HasTimestamps => ({
  deletedAt: data.deleted_at ? moment.utc(data.deleted_at) : undefined,
  createdAt: moment.utc(data.created_at),
  updatedAt: moment.utc(data.updated_at),
});
