import JsonObject from '../types/JsonObject';
import { timestamps } from '../factories/helpers';

export const Group = (data: JsonObject) => ({
  id: data.id as number,
  name: data.name as string,
  slug: data.slug as string,
  description: data.description as string,
  visible: data.visible as boolean,
  isAccepted: data.is_accepted as boolean,
  isMember: data.is_member as boolean,
  ...timestamps(data),
});

export interface Group extends ReturnType<typeof Group> {
  visible: boolean;
}
