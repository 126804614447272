import JsonObject from '../types/JsonObject';
import { Meta } from '../factories/Meta';

export function collectModels<T = unknown>(promise: Promise<any>, Model: { new (data: JsonObject): T }) {
  return promise.then(({ data, meta }) => ({
    data: data.map((data: JsonObject) => new Model(data)) as T[],
    meta: meta ? Meta(meta) : null,
  }));
}

export function makeModel<T = unknown>(promise: Promise<any>, Model: { new (data: JsonObject): T }) {
  return promise.then(({ data, meta }) => ({
    data: new Model(data),
    meta: meta ? Meta(meta) : null,
  }));
}
