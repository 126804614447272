import querystring from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import JsonObject from '../../types/JsonObject';
import { usePrevious } from 'react-use';

export default function useRouterQueryParams(defaultParams: JsonObject) {
  const location = useLocation();
  const history = useHistory();
  const query = useMemo(() => ({ ...querystring.parse(location.search) }), [location]);
  const [params, setParams] = useState<JsonObject>({ ...defaultParams, ...query });

  const previousQuery = usePrevious(query);
  useEffect(() => {
    if (query !== previousQuery) {
      setParams((old) => ({ ...old, ...query }));
    }
  }, [params, previousQuery, query]);

  const setParam = useCallback(
    (key: string, value: any) => {
      history.push({
        pathname: location.pathname,
        search: querystring.stringify({ ...query, [key]: value }),
      });
    },
    [history, location.pathname, query]
  );

  const setMultipleParams = useCallback((params: JsonObject) => {
    history.push({
      pathname: location.pathname,
      search: querystring.stringify({ ...query, ...params }),
    });
  }, []);

  const clearParams = useCallback(() => {
    history.push({
      pathname: location.pathname,
      search: querystring.stringify({}),
    });
  }, []);

  return { params, setParam, setParams: setMultipleParams, clearParams };
}

export function useBrowserQueryParams(defaultParams: JsonObject) {
  const location = window.location;
  const history = useHistory();
  const query = useMemo(() => ({ ...querystring.parse(location.search) }), [location]);
  const [params, setParams] = useState<JsonObject>({ ...defaultParams, ...query });

  const previousQuery = usePrevious(query);
  useEffect(() => {
    if (query !== previousQuery) {
      setParams({ ...params, ...query });
    }
  }, [params, previousQuery, query]);

  const setParam = useCallback(
    (key: string, value: any) => {
      history.push({
        pathname: location.pathname,
        search: querystring.stringify({ ...query, [key]: value }),
      });
    },
    [history, location.pathname, query]
  );

  return { params, setParam };
}
