import { parseISO, formatDistanceToNow, format } from 'date-fns';

export class DateObject {
  public date: Date;

  constructor(date: string) {
    this.date = parseISO(date);
  }

  static create(date?: string) {
    return date ? new DateObject(date) : undefined;
  }

  get ago() {
    return formatDistanceToNow(this.date);
  }

  get formatted() {
    return format(this.date, 'MMM do, yyyy');
  }
}
