import JsonObject from '../types/JsonObject';

export class University {
  public id: number;
  public name: string;

  constructor(data: JsonObject) {
    this.id = data.id;
    this.name = data.name;
  }
}
