import { HasTimestamps, timestamps } from './helpers';
import JsonObject from '../types/JsonObject';

export interface Profession extends HasTimestamps {
  id: number;
  name: string;
  specify?: string;
  specifyRequired: boolean;
  hasChildren: Boolean;
  parent?: Profession;
}

export const Profession = (data: JsonObject): Profession => {
  return {
    id: data.id,
    name: data.name,
    specify: data.specify,
    specifyRequired: !!data.specify_required,
    hasChildren: !!data.has_children,
    parent: data.parent ? Profession(data.parent) : undefined,
    ...timestamps(data),
  };
};
