import { TableCell } from '../components/Table';
import Badge from '../components/Badge';
import { dateAgo } from '../services/utils/date';
import moment from 'moment';

const MailTemplateTable: TableCell<any>[] = [
  {
    label: 'Name',
    value: (data: any) => data.name,
  },
  {
    label: 'Status',
    value: (data: any) =>
      data.sent_at != null ? (
        <Badge color="green">Sent {dateAgo(moment.utc(data.sent_at).toISOString())} ago</Badge>
      ) : (
        <Badge>Draft</Badge>
      ),
  },
];

export default MailTemplateTable;
