import JsonObject from '../types/JsonObject';
import { useContext, createContext, useEffect } from 'react';
import isEqual from 'lodash/isEqual';

type HelmetInterpolationContextType = {
  setData(data: JsonObject): void;
  data: JsonObject;
};

export const HelmetInterpolationContext = createContext<HelmetInterpolationContextType>({
  setData() {},
  data: {},
});

export const useHelmetInterpolation = () => {
  const route = useContext(HelmetInterpolationContext);
  if (route === undefined) {
    throw new Error('useCurrentRoute must be used inside a CurrentRouteContext');
  }
  return route;
};

export const useHelmetData = (data: JsonObject) => {
  const { setData, data: previousData } = useHelmetInterpolation();
  useEffect(() => {
    if (!isEqual(data, previousData)) {
      setData(data);
    }
  }, [data, previousData]);
};
