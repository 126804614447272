import JsonObject from '../types/JsonObject';
import { Role } from '../factories/Role';
import { FellowshipProfile } from './FellowshipProfile';
import { DateObject } from './DateObject';
import { Model } from './Model';

type Address = {
  streetAddress1: string;
  streetAddress2?: string;
  country?: string;
  state?: string;
  postcode?: string;
  phone?: string;
  city?: string;
};

export enum UserStates {
  NO_PAYMENT = 'no-payment',
  MEMBERSHIP_EXPIRED = 'membership-expired',
  MISSING_TERMS = 'missing-terms',
  MISSING_FIELDS = 'missing-fields',
}

export const PAM_ROLES = ['professional-association-member', 'limited-pam', 'international-pam', 'fcsc-pam'];

export class User extends Model {
  public id: number;
  public email: string;
  public name: string;
  public emailSecondary: string;
  public organization: string;
  public unsubscribed: boolean;
  public interim: boolean;
  public isAdmin: string;
  public lastLogin?: DateObject;
  public subscriptionEnds?: DateObject;
  public roleId: number;
  public role?: Role;
  public professionId: number;
  public termsRequired: boolean;
  public paymentRequired: boolean;
  public state: UserStates;
  public interests: any[];
  public gender: string;
  private _fellowshipProfile?: JsonObject;
  public address?: Address;
  public createdAt!: DateObject;
  public updatedAt!: DateObject;
  public deletedAt!: DateObject;
  public membersDirectoryConsent: boolean;

  constructor(data: JsonObject) {
    super();
    this.id = data.id;
    this.name = data.name;
    this.email = data.email;
    this.emailSecondary = data.email_secondary;
    this.organization = data.organization;
    this.unsubscribed = data.unsubscribed;
    this.interim = data.interim;
    this.isAdmin = data.is_admin;

    this.roleId = data.role_id;
    this.professionId = data.profession_id;
    this.role = data.role ? Role(data.role) : undefined;
    this.termsRequired = data.terms_required ?? undefined;
    this.paymentRequired = data.payment_required ?? undefined;
    this.state = data.state;
    this.interests = data.interests;
    this.gender = data.gender;
    this.membersDirectoryConsent =
      data.members_directory_consent != null ? Boolean(data.members_directory_consent) : true;
    this._fellowshipProfile = data.fellowship_profile;
    this.address = {
      streetAddress1: data.address?.street_address_1,
      streetAddress2: data.address?.street_address_2,
      country: data.address?.country,
      state: data.address?.state,
      postcode: data.address?.postcode,
      phone: data.address?.phone,
      city: data.address?.city,
    };

    this.buildTimestamps(['last_login', 'subscription_ends', 'created_at', 'updated_at', 'deleted_at'], data);
  }

  getFellowshipProfile() {
    return this._fellowshipProfile ? new FellowshipProfile(this._fellowshipProfile) : undefined;
  }

  toArray() {
    return {
      id: this.id,
      name: this.name,
      email: this.email,
      email_secondary: this.emailSecondary,
      organization: this.organization,
      unsubscribed: this.unsubscribed,
      interim: this.interim,
      is_admin: this.isAdmin,
      role_id: this.roleId,
      profession_id: this.professionId,
      role: this.role,
      terms_required: this.termsRequired,
      payment_required: this.paymentRequired,
      state: this.state,
      interests: this.interests,
      gender: this.gender,
      members_directory_consent: this.membersDirectoryConsent,
      address: this.address,
    };
  }
}
