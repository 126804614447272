import { useDialogs } from '../../components/Dialogs/DialogManager';
import { User, UserStates } from '../../models/User';
import { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { usePrevious } from 'react-use';
import { useRouteByName } from '../Router';

export function useAuthState(user?: User) {
  const previousUser = usePrevious(user);
  const { openDialog } = useDialogs();
  const location = useLocation();
  const routeByName = useRouteByName();
  const history = useHistory();

  const checkUserStates = useCallback(() => {
    if (location.pathname.includes('register')) return;

    switch (user?.state) {
      case UserStates.MEMBERSHIP_EXPIRED:
        if (location.pathname !== routeByName('renewal')) {
          history.push(routeByName('renewal'));
        }
        return;
      case UserStates.MISSING_FIELDS:
        return;
    }
  }, [location.pathname, openDialog, user?.state]);

  useEffect(() => {
    if (user !== previousUser) {
      checkUserStates();
    }
  }, [checkUserStates, previousUser, user]);
}
