import { PredicatesContext } from './../../types/RouteStructure';

type PredicateResult = string | undefined;

export function userHasRole(roles: string[]) {
  return (context: PredicatesContext): PredicateResult => {
    return roles.includes(context.user?.role?.slug) ? undefined : 'You are not autorized to view this page';
  };
}

export function isNotInterim(context: PredicatesContext): PredicateResult {
  return context.user && context.user.interim === false
    ? undefined
    : 'Your account has not been granted access to this page';
}
