import JsonObject from '../types/JsonObject';
import { timestamps } from '../factories/helpers';

export const Stream = (data: JsonObject) => ({
  id: data.id,
  name: data.name,
  slug: data.slug,
  description: data.description,
  url: data.url,
  active: data.active,
  ...timestamps(data),
});

export interface Stream extends ReturnType<typeof Stream> {}
