import JsonObject from '../types/JsonObject';
import { DateObject } from './DateObject';
import { FellowshipProfile } from './FellowshipProfile';
import { Model } from './Model';

export class FinalEvaluation extends Model {
  public id: number;
  public fellowId: number;
  public programDirectorId: number;
  public content: JsonObject;
  public snapshot: JsonObject;
  public programDirector?: FellowshipProfile;
  public fellow?: FellowshipProfile;
  public createdAt!: DateObject;
  public updatedAt!: DateObject;

  constructor(data: JsonObject) {
    super();
    this.id = data.id;
    this.fellowId = data.fellow_id;
    this.programDirectorId = data.program_director_id;
    this.content = data.content;
    this.snapshot = data.snapshot;
    this.programDirector = data.program_director ? new FellowshipProfile(data.program_director) : undefined;
    this.fellow = data.fellow ? new FellowshipProfile(data.fellow) : undefined;
    this.buildTimestamps(['created_at', 'updated_at'], data);
  }
}
