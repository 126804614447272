import { formatDistance, parseISO, isToday, format, isAfter } from 'date-fns';

export const dateAgo = (date: string) => {
  return formatDistance(parseISO(date), new Date(), { addSuffix: true });
};

export const todayOrDate = (dateString: string) => {
  const date = parseISO(dateString);

  if (isToday(date)) {
    return 'Today';
  }
  return format(date, 'PPP');
};

export const isPast = (dateString: string) => {
  return isAfter(new Date(), parseISO(dateString));
};
