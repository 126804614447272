import { Icon } from '@ajoelp/icons';
import { ToastTypes } from '@ajoelp/toast';
import { ToastMessageProps } from '@ajoelp/toast/dist/components/ToastMessage';
import { Transition } from '@headlessui/react';

const TYPES: any = {
  [ToastTypes.Error]: {
    color: 'text-red-400',
    icon: 'XCircle',
  },
  [ToastTypes.Success]: {
    color: 'text-green-400',
    icon: 'CheckCircle',
  },
  [ToastTypes.Info]: {
    color: 'text-blue-400',
    icon: 'QuestionMarkCircle',
  },
  [ToastTypes.Warning]: {
    color: 'text-yellow-400',
    icon: 'ExclamationCircle',
  },
};

export function ToastMessage(props: ToastMessageProps) {
  const IconType = TYPES[props.options.type ?? ToastTypes.Info];
  return (
    <Transition
      show={!!props.active}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transform ease-out duration-300 transition"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className="m-4 bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
    >
      <div className="p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0">
            <Icon icon={IconType.icon} variant="outline" className={`h-6 w-6 ${IconType.color}`} />
          </div>
          <div className="ml-3 w-0 flex-1 pt-0.5">
            <div className="text-sm font-medium text-gray-900" dangerouslySetInnerHTML={{ __html: props.message }} />
          </div>
          <div className="ml-4 flex-shrink-0 flex">
            <button
              onClick={props.close}
              className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span className="sr-only">Close</span>
              <Icon icon="X" className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </Transition>
  );
}
