import { timestamps } from './helpers';
import { Role } from './Role';
import JsonObject from '../types/JsonObject';
import { FellowshipProfile } from '../models/FellowshipProfile';

export enum UserStates {
  NO_PAYMENT = 'no-payment',
  MEMBERSHIP_EXPIRED = 'membership-expired',
  MISSING_TERMS = 'missing-terms',
  MISSING_FIELDS = 'missing-fields',
}

export const User = (data: JsonObject) => ({
  id: data.id,
  name: data.name,
  email: data.email,
  emailSecondary: data.email_secondary,
  organization: data.organization,
  address: {
    streetAddress1: data.address?.street_address_1,
    streetAddress2: data.address?.street_address_2,
    country: data.address?.country,
    state: data.address?.state,
    postcode: data.address?.postcode,
    phone: data.address?.phone,
    city: data.address?.city,
  },
  unsubscribed: data.unsubscribed,
  interim: data.interim,
  lastLogin: data.last_login,
  lastOrder: data.last_order,
  orders: data.orders,
  isAdmin: data.is_admin,
  subscription_ends: data.subscription_ends,
  role_id: data.role_id,
  profession_id: data.profession_id,
  role: data.role ? Role(data.role) : undefined,
  termsRequired: data.terms_required ?? undefined,
  paymentRequired: data.payment_required ?? undefined,
  state: data.state,
  interests: data.interests,
  gender: data.gender,
  fellowshipProfile: data.fellowship_profile ? new FellowshipProfile(data.fellowship_profile) : null,
  ...timestamps(data),
});

export interface User extends ReturnType<typeof User> {
  state: UserStates;
}
