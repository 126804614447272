import JsonObject from '../types/JsonObject';
import { timestamps } from '../factories/helpers';

export const News = (data: JsonObject) => ({
  id: data.id,
  name: data.name,
  slug: data.slug,
  content: data.content,
  url: data.url,
  excerpt: data.excerpt,
  thumbnail: data.thumbnail,
  ...timestamps(data),
});

export interface News extends ReturnType<typeof News> {}
