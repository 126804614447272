import { Transition } from '@headlessui/react';
import Loader from './Loader';

export function LoadingOverlay() {
  return (
    <Transition
      appear={true}
      show={true}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="w-screen h-screen flex items-center justify-center bg-white">
        <div>
          <Loader className="text-gray-400" />
        </div>
      </div>
    </Transition>
  );
}
