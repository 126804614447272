import { ReactNode } from 'react';
import classnames from 'classnames';

const TYPES = {
  h1: 'text-2xl font-bold leading-7 sm:text-3xl sm:leading-9',
  h3: 'text-lg leading-6 font-medium text-gray-900',
  title: 'text-lg leading-6 font-medium',
  subtitle: 'text-sm leading-5 text-gray-500',
  body: 'text-sm leading-5',
  label: 'text-sm leading-5 font-medium text-gray-500',
  overline: 'uppercase text-sm font-medium',
};

export type TypographyProps = {
  children: ReactNode;
  type?: keyof typeof TYPES;
  className?: string;
  name?: string;
};

export default function Typography({ children, className, name, type = 'body' }: TypographyProps) {
  const classes = classnames(TYPES[type], className);
  return (
    <p className={classes} data-testid={name}>
      {children}
    </p>
  );
}
