import { TableCell } from '../components/Table';
import Badge from '../components/Badge';
import { dateAgo } from '../services/utils/date';
import moment from 'moment';

const TYPES = [
  {
    label: 'Page',
    scope: 'App\\Page',
    value: (data: any) => data?.name ?? '-',
  },
  {
    label: 'Educational Resource Item',
    scope: 'App\\EducationalResourceItem',
    value: (data: any) => data?.content?.name ?? '-',
  },
  {
    label: 'News',
    scope: 'App\\News',
    value: (data: any) => data?.name ?? '-',
  },
  {
    label: 'Modules',
    scope: 'App\\Module',
    value: (data: any) => data?.name ?? '-',
  },
  {
    label: 'Streams',
    scope: 'App\\Stream',
    value: (data: any) => data?.name ?? '-',
  },
];

const findType = (scope: string) => TYPES.find((a) => a.scope === scope);

const TrackingTable: TableCell<any>[] = [
  {
    label: 'User',
    value: (data: any) => (
      <p>
        {data.user.name} ({data.user.email})
      </p>
    ),
  },
  {
    label: 'Type',
    value: (data: any) => <p>{findType(data.scope_type)?.label}</p>,
  },
  {
    label: 'Title',
    value: (data: any) => <p>{findType(data.scope_type)?.value(data.scope)}</p>,
  },
  {
    label: 'Date',
    value: (data: any) => <p>{data.date}</p>,
  },
];

export default TrackingTable;
