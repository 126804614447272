import { TableCell } from '../components/Table';
import get from 'lodash/get';

const ProfessionTable: TableCell<any>[] = [
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'Parent',
    value: (data) => {
      if (!get(data, 'parent.name')) {
        return <div />;
      }
      return (
        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-blue-100 text-blue-800">
          {get(data, 'parent.name')}
        </span>
      );
    },
  },
];

export default ProfessionTable;
