import { TableCell } from '../components/Table';

const ModuleTable: TableCell<any>[] = [
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'Questions',
    value: 'questions_count',
  },
  {
    label: 'Active',
    value: (data) => {
      const color = data.active ? 'bg-green-500' : 'bg-red-500';
      return (
        <div className="flex w-full">
          <div className={`w-3 h-3 ${color} rounded-full mx-auto`} />
        </div>
      );
    },
  },
];

export default ModuleTable;
