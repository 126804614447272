import JsonObject from '../types/JsonObject';
import axios from 'axios';
import { AUTH_TOKEN_KEY, IMPERSONATE_TOKEN } from '../constants';
import { environment } from '../../environments/environment.prod';

const apiURL = environment.apiUrl || '';

type Methods = 'get' | 'post' | 'put' | 'delete' | 'patch';

interface ApiClientConfig {
  headers?: JsonObject;
  authRedirects?: boolean;
}

export const defaultHttp = axios.create({
  baseURL: apiURL,
});

let http = defaultHttp;

export function setHttp(value: any) {
  http = value;
}

// Setup some interceptors
defaultHttp.interceptors.response.use((response) => {
  const { data } = response ?? {};

  if (data?.access_token) {
    window.localStorage.setItem(AUTH_TOKEN_KEY, data.access_token);
  }

  return response;
});

defaultHttp.interceptors.request.use((config) => {
  const token = window.localStorage.getItem(AUTH_TOKEN_KEY);
  const impersonateToken = window.localStorage.getItem(IMPERSONATE_TOKEN);
  if (impersonateToken) {
    config.headers['Authorization'] = `Bearer ${impersonateToken}`;
  } else if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

export default async function apiClient(
  endpoint: string,
  method: Methods,
  data: JsonObject | FormData = {},
  clientConfig: ApiClientConfig = {}
) {
  const isGet = method === 'get';

  const isFormData = data instanceof FormData;

  const body = isFormData ? (data as FormData) : JSON.stringify(data);

  const config: any = {
    method: method.toUpperCase(),
    body: isGet ? null : body,
    headers: {
      Accept: 'application/json',
      ...(clientConfig.headers || {}),
    },
  };

  if (!isFormData) {
    config.headers['Content-Type'] = 'application/json';
  }

  return http({
    method: method,
    url: endpoint,
    params: isGet ? data : null,
    data: body,
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(clientConfig.headers || {}),
    },
  }).then(({ data }) => data);
}
